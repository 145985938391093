import React from 'react'
import { graphql } from 'gatsby'
import Seo from "../components/seo"
import LayoutTwo from '../components/layoutTwo'
import BreadcrumbModule from '../components/BreadcrumbModule/BreadcrumbModule'
import StaticDesc from '../components/StaticDesc/StaticDesc'

const Terms = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <LayoutTwo popularSearch={PageData?.select_popular_search?.title}>
            <div className="layout-padding-top"></div>
            <BreadcrumbModule {...PageData.choose_menu[0]}
            />
            {PageData?.add_page_modules?.length > 0 && PageData.add_page_modules?.map((module, index) => {
                return (
                    <>
                        {module.strapi_component === "page-modules.plain-content" && <div className='layout-grey-gradient-bg static-page-wrapper'>
                            <StaticDesc
                                title={PageData.title}
                                content={module}
                            />
                        </div>}
                    </>
                )
            }
            )}
        <div className='divider-line'></div>
        </LayoutTwo>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <Seo title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
    )
}

export default Terms


export const query = graphql`
    query ($page_id: String) {
      strapiPage(id: {eq: $page_id}) {
          ...PageFragment
          add_page_modules {
                ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
                    ...PlainContentFragment
                }
          }
      }
    }
  `